@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  color:#FFF;
}

.listItem{
  text-decoration: none;
  color: #000;
}

.song-container {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.player {
  /*min-height: 20vh;*/
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}


@media only screen and (max-width: 768px) {

  .player{
    width: 50%;
  }
}
