/*-----------------------------------------------------------playerDiv--------------------------------------------------------------*/

a:link {
  color: rgb(100, 100, 100);
}

/* visited link */
a:visited {
  color: rgb(100, 100, 100);
}

/* mouse over link */
a:hover {
  color: rgb(100, 98, 98);
}

/* selected link */
a:active {
  color: rgb(100, 100, 100);
}


.musicDivPlayer {
  width:calc(100vw - 98px);
}

.test {
  width: 250px;
  display: flex;
  align-items: center;
  /*justify-content: end;*/
  flex: end;

  input {
      appearance: none;
      border-radius: 10px;
      width: 120px;
      background-color: #494A4D;
      height: 5px;
      outline: none;
      margin-right: 15px;
  }

  input::-webkit-slider-thumb {
      appearance: none;
      height: 14px;
      width: 14px;
      background-color: #000;
      border-radius: 50%;
  }

  .volumeButton {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
  }
}

.playPause{
  margin: 0 5px;
}

.music {
  display: flex;
  color: #000;
  
  img {
      flex: none;
      height: 35px;
      border-radius: 2px;
      overflow: hidden;
  }
}

.inputButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons {
  font-size: 1rem;
  display: flex;
  width: 225px;
  bottom: 8px;
  align-items: center;
}

.players {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.progressBar {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  bottom: 8px;
  right: 10px;
}

.Pduration {
  position: relative;
  left: 15px;
  color: #000;
  font-size: 15px;
  font-weight: bold;
}

.PcurrentTime {
  position: relative;
  right: 10px;
  color: #000;
  width: 35px;
  font-size: 18px;
  font-weight: bold;
}

.track {
  position: absolute;
  display: block;
  top: 1px;
  width: 100%;
  height: 6px;
  overflow: hidden;
  border-radius: 1rem;
  transform: translateY(-50%);
}



.animate-track {
  background: rgb(204, 204, 204);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0%);
  pointer-events: none;
}

.player-bar {
  position: absolute;
  cursor: pointer;
  display: block;
  top: 1px;
  left: -2px;
  width: 100%;
  transform: translateY(-50%);
}

.PcurrentTime {
  left: 5px;
}

.currentProgress {
  --seek-before-width: 0px;

  border-radius: 10px;
  width: 380px;
  margin: auto;
  background-color: #494A4D;
  height: 5px;
  outline: none;
}

.inicialSearch {
  width: 100%;
  justify-content: center;
  
  div {
    grid-area: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 13px;

      p {
          margin-right: 5px;
      }
  }
}


@media only screen and (max-width: 820px) {
  .buttons {
      top: 0;
      right: 5%;
      align-items: center;
  }

  .progressBar {
      display: flex;
      align-items: center;
      width: 88vw;
      position: relative;
      bottom: 0px;
      right: -10%;
  }

  input {
      margin: 0;
  }

  .inputButtons{
    padding-bottom: 25px;
    padding-right: 10px;
  }

  .PcurrentTime {
    font-size: 14px;
    left: 2px;
  }

}

@media only screen and (max-width: 575px) {
  .musicDivPlayer {
    width:calc(100vw - 30px);
  }

  .inicialSearch {
    width: 100%;
    div {
      grid-area: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;
      font-size: 13px;
  
        p {
            margin-right: 5px;
        }
    }
  }
  
  .progressBar {
      right: 30px;
  }

  .buttons {
      width: 148px;
      top: 0px;
      right: 0;
      left: '-18%';
      align-items: center;
  }

  .inputButtons{
    padding-bottom: 25px;
    padding-right: 10px;
  }

  .PcurrentTime {
    font-size: 14px;
    left: 2px;
  }

  .volumeButton {
    left: 2px;
  }
}