@media (min-width: 940px) {
	ytmusic-player[player-ui-state_="MINIPLAYER"] {
		display: none !important;
	}

	#nav-bar-background,
	#nav-bar-shadow {
		display: none !important;
	}

	#main-panel {
		display: none !important;
	}

	yt-formatted-string {
		font-family: 'Segoe UI Variable', 'Segoe UI', sans-serif !important;
	}

	#ytmusic-search-box {
		position: absolute;
		top: 0;
		left: 24px;
		width: 23vw !important;
	}

	ytmusic-tabs.ytmusic-tabbed-search-results-renderer:before {
		content: '';
		width: calc(100vw - 150px);
		position: absolute;
		bottom: 1px;
		height: 60vh;
		background-color: #202020
	}

	ytmusic-section-list-renderer.ytmusic-tabbed-search-results-renderer {
		padding-top: 24px;
	}

	ytmusic-search-box {
		box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.075) !important;
	}

	ytmusic-tabs.ytmusic-tabbed-search-results-renderer {
		box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.125) !important;
	}

	ytmusic-tabs.ytmusic-tabbed-search-results-renderer > .tab-container {
		padding-bottom: 0 !important;
	}

	.search-box.ytmusic-search-box {
		visibility: visible !important;
		color: #aaa;
		margin: 0 0 8px;
		height: 42px;
		justify-content: initial;
	}

	ytmusic-search-box {
		overflow: visible;
		margin-left: 12px;
		width: 38px !important;
	}

	#left-content {
		margin-top: 16px;
		margin-left: 16px;
	}

	#right-content {
		position: absolute;
		bottom: 0;
		left: 3px;
		margin: 0 !important;
		display: table;
	}

	.ytmusic-settings-button {
		margin-left: -5px;
		margin-bottom: 24px;
		margin-top: 24px;
		height: 36px !important;
		width: 36px !important;
	}

	#right-content ytmusic-cast-button {
		margin-bottom: 8px;
	}

	#right-content ytmusic-cast-button svg {
		fill: #858585 !important;
	}

	ytmusic-item-section-tabbed-header-renderer {
		flex-flow: row;
	}

	ytmusic-nav-bar {
		height: 100vh !important;
		padding: unset;
		width: 78px !important;
		align-items: flex-start;
		background-color: #131313;
		z-index: 100000;
	}

	ytmusic-pivot-bar-item-renderer {
		display: flex;
		flex-flow: column;
		font-size: 7.5pt !important;
		line-height: 11pt;
		color: #5f5f5f;
		padding: 0 !important;
		margin: 0 !important;
	}

	.tab-icon.ytmusic-pivot-bar-item-renderer {
		display: inline-flex !important;
		color: #5f5f5f;
	}

	.tab-icon.ytmusic-pivot-bar-item-renderer,
	.ytmusic-pivot-bar-item-renderer yt-icon {
		height: 42px !important;
		width: 40px !important;
	}

	.tab-icon.ytmusic-pivot-bar-item-renderer svg {
		height: 30px !important;
		width: 30px !important;
		padding: 2px;
		margin: 0 !important;
	}

	ytmusic-pivot-bar-item-renderer:hover {
		background-color: rgba(255, 255, 255, 0.0) !important;
		color: #656565;
	}

	ytmusic-pivot-bar-item-renderer yt-icon {
		padding: 4px 0;
	}

	ytmusic-pivot-bar-item-renderer.iron-selected yt-icon {
		color: #ff0000 !important;
		background-color: rgba(255, 255, 255, 0.15) !important;
		border-radius: 8px;
		margin: -12px 4px -12px 0;
		border: 8px solid rgba(255, 255, 255, 0);
		padding: 0 0;
	}

	ytmusic-pivot-bar-item-renderer.iron-selected yt-icon:hover {
		border: 8px solid rgba(255, 255, 255, 0);
	}

	ytmusic-pivot-bar-item-renderer.iron-selected {
		color: #ff0000 !important;
		border-left: 6px solid #ff0000;
		padding: 0 2px !important;
		margin: 16px 0 !important;
		font-size: 0 !important;
		line-height: unset !important;
	}

	ytmusic-pivot-bar-item-renderer.iron-selected:hover {
		background-color: rgba(255, 255, 255, 0) !important;
	}

	ytmusic-pivot-bar-item-renderer:hover yt-icon {
		background-color: rgba(255, 255, 255, 0.15) !important;
		border-radius: 8px;
		border-left: 6px solid rgba(255, 255, 255, 0);
		border-right: 6px solid rgba(255, 255, 255, 0);
		margin-left: 0 !important;
	}

	.ytmusic-nav-bar {
		flex-flow: column;
	}

	.center-content.ytmusic-nav-bar {
		height: 320px;
		flex-flow: column-reverse;
		justify-content: flex-end;
		padding: 54px 0;
	}

	ytmusic-pivot-bar-item-renderer {
		font-size: 14px;
		margin: 0 !important;
		padding: 8px 16px !important;
	}

	input.ytmusic-search-box,
	#placeholder.ytmusic-search-box {
		font-size: 14px;
	}

	.ytmusic-search-box paper-icon-button {
		width: 32px;
		height: 32px;
	}

	.search-container.ytmusic-search-box,
	ytmusic-search-box {
		border-radius: unset !important;
	}
	#placeholder.ytmusic-search-box {
		padding: 8px 128px 8px 0;
	}

	ytmusic-search-box[has-query] input.ytmusic-search-box,
	ytmusic-search-box[opened] input.ytmusic-search-box {
		color: #f2f2f2 !important;
	}

	ytmusic-search-box[opened] .ytmusic-search-box .search-box,
	ytmusic-search-box[has-query] .ytmusic-search-box .search-box {
		margin: 0 !important;
		border-radius: 4px;
		background-color: #3f3f3f !important;
	}

	ytmusic-search-box[opened],
	ytmusic-search-box[has-query] {
		position: absolute;
		top: 24px;
		left: calc(15vw + 78px);
		width: 60vw !important;
		border: unset;
	}

	ytmusic-search-box[has-query] {
		background-color: rgba(0, 0, 0, 0);
	}

	#suggestions[has-query] {
		background-color: rgba(0, 0, 0, 0);
	}

	#suggestions {
		background-color: #131313;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}

	ytmusic-search-suggestions-section.ytmusic-search-box {
		border-top: unset;
	}

	ytmusic-search-suggestion {
		height: 42px;
		font-size: 14px;
		background-color: rgba(0, 0, 0, 0);
	}

	ytmusic-search-suggestion:hover {
		background-color: rgba(255, 255, 255, 0.15) !important;
	}
	ytmusic-search-suggestion {
		transition: 150ms;
		margin-left: 1vw;
		margin-right: 1vw;
		border-radius: 4px;
	}

	iron-icon.ytmusic-search-suggestion {
		display: none;
	}

	ytmusic-pivot-bar-item-renderer.iron-selected {
		background-color: rgba(0, 0, 0, 0.05);
	}

	ytmusic-item-section-renderer:not(.memberships-and-purchases) #items.ytmusic-item-section-renderer > ytmusic-shelf-renderer.ytmusic-item-section-renderer,
	ytmusic-item-section-renderer:not(.memberships-and-purchases) #items.ytmusic-item-section-renderer > ytmusic-grid-renderer.ytmusic-item-section-renderer,
	ytmusic-item-section-renderer:not(.memberships-and-purchases) #items.ytmusic-item-section-renderer > ytmusic-message-renderer.ytmusic-item-section-renderer,
	ytmusic-item-section-tabbed-header-renderer,
	.header.ytmusic-carousel-shelf-renderer,
	.content-container.ytmusic-detail-header-renderer,
	.tab-container.ytmusic-tabs,
	.content.ytmusic-tabbed-search-results-renderer,
	.content-container.ytmusic-data-bound-header-renderer,
	ytmusic-header-renderer.ytmusic-browse-response {
		max-width: calc(100vw - 220px);
		margin: 6vh 5.5vw 3vh 5.5vw;
		padding: 0 !important;
		overflow-x: hidden !important;
		margin-right: 0 !important;
	}

	.content.ytmusic-tabbed-search-results-renderer {
		margin: 6vh 0 !important;
		overflow-x: hidden !important;
		width: calc(100vw - 150px) !important;
		max-width: calc(100vw - 150px) !important;
	}

	.content.ytmusic-tabbed-search-results-renderer ytmusic-responsive-list-item-renderer {
		padding: 0.5vh 1vw;
	}

	.content.ytmusic-tabbed-search-results-renderer .more-button {
		margin: 2vh 0 !important;
	}

	ytmusic-tabs.ytmusic-tabbed-search-results-renderer {
		padding-left: 0 !important;
	}

	ytmusic-tabs.ytmusic-tabbed-search-results-renderer div.ytmusic-tabs {
		margin: 6vh 5.5vw 0.5vh 5.5vw !important;
	}

	ytmusic-section-list-renderer.ytmusic-tabbed-search-results-renderer ytmusic-shelf-renderer > h2 {
		margin: 2vh 0 !important;
	}

	ytmusic-section-list-renderer.ytmusic-tabbed-search-results-renderer ytmusic-shelf-renderer > h2 yt-formatted-string {
		font-size: 20pt !important;
		color: #d5d5d5 !important;
		line-height: 30pt !important;
	}

	#contents.ytmusic-section-list-renderer > *.ytmusic-section-list-renderer:not(.fullbleed) {
		width: calc(100vw - 220px);
		padding-left: 5.5vw;
		margin: 0;
	}

	#items.ytmusic-carousel {
		width: calc(100vw - 200px);
		margin: auto;
	}

	.next-items-button.ytmusic-carousel {
		right: 8.5vw;
		top: calc(100% - 65%);
		z-index: 2 !important;
	}

	.previous-items-button.ytmusic-carousel {
		left: 8vw;
		top: calc(100% - 65%);
	}

	#content.ytmusic-app > *.ytmusic-app:not([immersive-mode]).ytmusic-app,
	#content.ytmusic-item-thumbnail-overlay-renderer {
		padding: 0;
	}

	ytmusic-play-button-renderer {
		--ytmusic-play-button-icon-color: #fff !important;
	}

	.title.ytmusic-carousel-shelf-basic-header-renderer,
	ytmusic-carousel-shelf-basic-header-renderer[style_="MUSIC_CAROUSEL_SHELF_BASIC_HEADER_STYLE_DISPLAY_TWO"] .title.ytmusic-carousel-shelf-basic-header-renderer,
	.primary-text.ytmusic-tastebuilder-shelf-renderer {
		font-size: var(--ytmusic-display-2_-_font-size);
		color: #f0f0f0;
		line-height: 24pt;
	}

	ytmusic-tastebuilder-shelf-renderer {
		margin: auto;
	}

	.logo.ytmusic-nav-bar {
		height: 15px;
		width: 48px;
		margin-left: -2px;
	}

	ytmusic-carousel-shelf-basic-header-renderer {
		padding: 16px 42px 4px;
	}

	ytmusic-carousel-shelf-renderer {
		background-color: #202020;
		margin-left: 0;
		margin-bottom: 0 !important;
		top: 0 !important;
		width: calc(100vw - 98px);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	#contents.ytmusic-section-list-renderer > ytmusic-shelf-renderer.ytmusic-section-list-renderer:not(:last-child):not([is-empty_]).ytmusic-section-list-renderer {
		margin-bottom: 0;
	}

	#end-items.ytmusic-item-section-tabbed-header-renderer {
		border-top: unset;
	}

	#end-items {
		justify-content: flex-end;
		height: 48px !important;
	}

	#header.ytmusic-item-section-renderer {
		border-bottom: 0 solid #121212 !important;
		background-color: #202020 !important;
		padding-bottom: unset;
		box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.125) !important;
	}

	#header.ytmusic-item-section-renderer > ytmusic-item-section-tabbed-header-renderer {
		padding-bottom: 0 !important;
		box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.075) !important;
	}

	ytmusic-header-renderer {
		overflow-y: hidden;
	}

	ytmusic-item-section-renderer[has-item-section-tabbed-header-renderer_] #header.ytmusic-item-section-renderer {
		top: 0 !important;
	}

	.tab.ytmusic-item-section-tab-renderer {
		color: #fafafa;
	}

	ytmusic-item-section-tab-renderer.iron-selected .tab.ytmusic-item-section-tab-renderer,
	.tab.selected.ytmusic-item-section-tab-renderer {
		color: #ff0000;
		font-weight: 700;
		border-bottom: 2px solid #ff0000;
	}

	body {
		background: #131313;
		font-family: 'Segoe Variable', sans-serif !important;
	}

	#items {
		margin-top: 16px;
	}

	#items.ytmusic-grid-renderer {
		grid-gap: 16px 32px;
	}

	#items.ytmusic-carousel .substring-group.ytmusic-two-row-item-renderer .subtitle.ytmusic-two-row-item-renderer {
		font-size: 13px;
	}

	#items.ytmusic-carousel > .ytmusic-carousel[aspect-ratio="MUSIC_TWO_ROW_ITEM_THUMBNAIL_ASPECT_RATIO_SQUARE"] {
		width: 138.25px;
	}

	#items.ytmusic-carousel > .ytmusic-carousel[aspect-ratio=MUSIC_TWO_ROW_ITEM_THUMBNAIL_ASPECT_RATIO_RECTANGLE_16_9] {
		width: calc(164.5px * 16/9);
	}

	ytmusic-two-row-item-renderer > a {
		border-radius: 8px !important;
	}

	ytmusic-app-layout > [slot="player-page"] {
		width: calc(100vw - 98px);
		margin-left: 78px;
		top: 0;
		height: calc(100vh - var(--ytmusic-player-bar-height));
	}

	.content.ytmusic-player-page {
		padding: unset;
	}

	ytmusic-player-page {
		background-color: #202020;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	#main-panel.ytmusic-player-page {
		align-items: flex-start;
	}

	.side-panel.ytmusic-player-page {
		width: calc(100vw);
		max-width: calc(100vw - 99px) !important;
		margin: 0 !important;
	}

	ytmusic-player-page:not([video-mode_]):not([player-fullscreened_]) #player.ytmusic-player-page {
		width: 768px;
		margin: 64px;
	}

	#main-panel.ytmusic-player-page {
		flex: unset;
		min-width: 42.5vw;
		background-color: #000;
		border-top-left-radius: 8px;
	}

	.top-bar.ytmusic-player-page {
		border-bottom: 1px solid #eee;
	}

	#main-panel.ytmusic-player-page {
		margin: 0 !important;
		padding-left: 12px !important;
		padding-right: 12px !important;
		justify-content: center;
	}

	paper-dropdown-menu.ytmusic-dropdown-renderer * {
		color: #333 !important;
		font-size: 14px !important;
	}

	.dropdown-content.ytmusic-dropdown-renderer {
		--paper-listbox-background-color: #202020;
		padding: 0;
	}

	paper-listbox.ytmusic-dropdown-renderer,
	ytmusic-dropdown-item-renderer {
		background-color: #202020;
		transition: 150ms;
	}

	paper-listbox.ytmusic-dropdown-renderer {
		padding: 4px 0;
	}

	ytmusic-dropdown-item-renderer:hover {
		background-color: #353535;
	}

	ytmusic-dropdown-item-renderer[is-selected] {
		background-color: #353535;
	}

	#right-content {
		padding: 14px 16px;
		width: 78px;
		align-items: flex-end;
	}

	ytmusic-detail-header-renderer,
	ytmusic-data-bound-header-renderer {
		background-color: rgba(255, 255, 255, 0);
		padding-top: 4vh;
		padding-bottom: 4vh;
	}

	ytmusic-list-item-renderer {
		height: 42px !important;
	}

	ytmusic-responsive-list-item-renderer {
		--ytmusic-list-item-height: 48px !important;
	}

	ytmusic-responsive-list-item-renderer[height-style_="MUSIC_RESPONSIVE_LIST_ITEM_HEIGHT_TALL"] {
		--ytmusic-list-item-height: 72px !important;
	}

	#contents.ytmusic-shelf-renderer > *.ytmusic-shelf-renderer:not(:last-child) {
		border-bottom: 1px solid #202020;
	}

	#contents.ytmusic-shelf-renderer > *.ytmusic-shelf-renderer:hover {
		background-color: #202020;
		border-radius: 3px;
	}

	.content-container.ytmusic-detail-header-renderer,
	.content-container.ytmusic-data-bound-header-renderer,
	ytmusic-header-renderer.ytmusic-browse-response {
		padding-top: unset;
		padding-bottom: 32px;
	}

	ytmusic-browse-response {
		background-color: #202020;
		width: calc(100vw - 98px);
		top: 8px;
		margin-bottom: 86px;
		margin-top: 2px;
		border-radius: 8px;
	}

	.title.ytmusic-responsive-list-item-renderer,
	.title.ytmusic-list-item-renderer,
	.artist.ytmusic-list-item-renderer {
		color: #fff !important;
		font-size: 14px;
	}

	html:not(.style-scope)::-webkit-scrollbar-track {
		background-color: #202020 !important;
	}

	html:not(.style-scope)::-webkit-scrollbar-thumb {
		background-color: #272727 !important;
	}

	#player-bar-background.ytmusic-app-layout,
	ytmusic-player-bar {
		display: flex;
		justify-content: center;
	}

	#player-bar-background {
		background: #202020 !important;
		overflow-y: visible !important;
	}

	#player-bar-background:before {
		position: absolute;
		bottom: -12px;
		content: '';
		width: 100vw;
		height: 18px;
		background: #131313 !important;
	}

	ytmusic-player-bar .menu.ytmusic-player-bar {
		margin: 0 2px 0 0 !important;
	}

	ytmusic-player-bar {
		background-color: unset;
		display: flex !important;
		justify-content: space-evenly !important;
	}

	ytmusic-cropped-square-thumbnail-renderer.ytmusic-detail-header-renderer img {
		border-radius: 8px;
	}

	ytmusic-app-layout > [slot="player-bar"],
	#player-bar-background {
		background: #202020 !important;
		width: calc(100vw - 98px);
		margin-left: 78px;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	ytmusic-immersive-header-renderer {
		background: #202020 !important;
		margin-left: 5.5vw;
		margin-right: 5.5vw;
		margin-top: 36px;
		border-radius: 8px;
	}

	.image.ytmusic-immersive-header-renderer ~ .content-container-wrapper.ytmusic-immersive-header-renderer .content-container.ytmusic-immersive-header-renderer {
		padding: 0 32px 32px;
	}

	.left-controls.ytmusic-player-bar paper-icon-button.ytmusic-player-bar,
	.left-controls.ytmusic-player-bar .spinner-container.ytmusic-player-bar,
	.toggle-player-page-button.ytmusic-player-bar {
		--iron-icon-fill-color: #f2f2f2;
	}

	.left-controls.ytmusic-player-bar tp-yt-paper-icon-button.ytmusic-player-bar,
	.left-controls.ytmusic-player-bar .spinner-container.ytmusic-player-bar {
		margin: 0 0 0 -8px;
	}

	.content-info-wrapper.ytmusic-player-bar .title.ytmusic-player-bar {
		color: #fff;
		font-size: 12px;
		line-height: 1.5em;
	}

	.content-info-wrapper.ytmusic-player-bar .byline.ytmusic-player-bar {
		font-size: 12px;
	}

	ytmusic-app-layout > [slot="player-bar"],
	#player-bar-background.ytmusic-app-layout {
		bottom: 12px;
		left: 0;
		transform: unset;
		overflow-x: hidden;
		overflow: visible;
	}

	#left-controls,
	#right-controls {
		grid-area: none !important;
		flex: initial !important;
		justify-content: center;
	}

	ytmusic-player-bar #progressContainer.paper-progress,
	.indeterminate.paper-progress::after {
		background-color: #eee !important;
	}

	.middle-controls.ytmusic-player-bar {
		position: absolute;
		margin: 14px 14px;
		justify-content: space-between;
		grid-area: none;
	}

	.carousel.ytmusic-carousel-shelf-renderer {
		margin: unset;
	}

	.content-info-wrapper.ytmusic-player-bar {
		flex: 1;
		margin: unset;
		max-width: 22.5vw;
	}

	.image.ytmusic-player-bar {
		height: 54px;
		z-index: 50;
		width: 54px;
		object-fit: cover;
		margin: 0 !important;
		margin-right: 14px !important;
		padding: 0 !important;
		border-radius: 4px !important;
	}

	.thumbnail-image-wrapper.ytmusic-player-bar {
		height: 56px;
		position: relative;
	}

	#progress-bar.ytmusic-player-bar:hover,
	#progress-bar.ytmusic-player-bar,
	#progress-bar.ytmusic-player-bar[focused] {
		--paper-slider-height: 2px !important;
	}

	ytmusic-player-bar > paper-slider .slider-knob-inner.paper-slider {
		margin: 0;
		border-radius: 6px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		width: 3px;
		height: 8px;
	}

	ytmusic-player-bar:hover > paper-slider .slider-knob-inner.paper-slider {
		box-shadow: 0 0 0 1px #fff;
	}

	#volume-slider .slider-knob-inner.paper-slider {
		border: 1px solid #ddd;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .2);
	}

	ytmusic-player-bar > paper-slider .slider-knob.paper-slider {
		margin-left: unset;
		width: 3px;
		height: 8px;
		top: 0;
	}

	ytmusic-player-bar > paper-slider #sliderContainer.paper-slider {
		height: 16px;
		margin-left: unset;
	}

	ytmusic-player-bar > paper-slider #sliderBar.paper-slider {
		padding: 6px 0 !important;
	}

	ytmusic-item-thumbnail-overlay-renderer:not([play-button-has-background_]):not([play-button-state="default"]) #background.ytmusic-item-thumbnail-overlay-renderer,
	ytmusic-item-thumbnail-overlay-renderer[indexed] #background.ytmusic-item-thumbnail-overlay-renderer {
		--ytmusic-background-overlay-background: rgba(0, 0, 0, .2) !important;
	}

	.description.ytmusic-detail-header-renderer {
		max-width: 490px !important;
	}

	div.metadata {
		max-width: 490px !important;
	}

	:root {
		--ytmusic-detail-header-renderer-description-width: 500px !important;
		--ytmusic-player-bar-height: 78px !important;
		--ytmusic-nav-bar-height: 54px !important;
		--ytmusic-display-2_-_font-family: 'Segoe UI Variable', 'Segoe UI', sans-serif !important;
		--ytmusic-display-2_-_font-size: 16pt !important;
		--ytmusic-display-2_-_font-weight: 500 !important;
		--ytmusic-display-1-font-size: 24pt !important;
	}

	#left-controls paper-icon-button {
		width: 38px;
		height: 38px;
	}

	.left-controls.ytmusic-player-bar {
		position: absolute;
		left: 2px;
		top: 12px;
	}

	#right-controls paper-icon-button {
		width: 34px;
		height: 34px;
		margin: 0;
	}

	ytmusic-player-expanding-menu {
		background-color: #202020;
	}

	#hover-time-info.ytmusic-player-bar {
		display: none !important;
	}

	.scroller.ytmusic-player-page {
		margin-left: 12px;
		padding-bottom: 8px;
	}

	#tabsContent:nth-child(3) paper-ripple {
		border-top-right-radius: 8px;
	}

	ytmusic-player-queue-item:hover {
		background-color: rgba(255, 255, 255, 0.15);
	}

	ytmusic-player-queue-item {
		border-radius: 4px;
		margin: 1vh 4vw;
		padding: 0 2vw;
		transition: background-color 75ms, 250ms;
		border-bottom: unset;
	}

	ytmusic-player-queue-item:not(:first-child)::after {
		position: absolute;
		content: '';
		height: 0.5px;
		left: 0;
		margin: 0 0.25vw;
		right: 0;
		top: -0.5vh;
		background-color: rgba(200, 200, 200, 0.15);
		pointer-events: none;
	}

	.top-bar.ytmusic-player-page {
		padding-top: 24px;
	}

	paper-listbox.ytmusic-menu-popup-renderer {
		border-radius: 6px;
	}

	.text.ytmusic-menu-service-item-renderer,
	.text.ytmusic-toggle-menu-service-item-renderer,
	.text.ytmusic-menu-navigation-item-renderer {
		color: #a5a5a5 !important;
		line-height: 12pt;
		font-size: 10.5pt;
		margin: 0;
		margin-bottom: 1px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.yt-simple-endpoint.ytmusic-menu-navigation-item-renderer,
	ytmusic-toggle-menu-service-item-renderer,
	ytmusic-menu-service-item-renderer {
		border-radius: 4px !important;
		height: 24px;
		padding: 4px 18px;
	}

	paper-listbox ytmusic-menu-service-item-renderer:first-of-type {
		margin-top: 13px;
		position: relative;
	}

	paper-listbox ytmusic-menu-service-item-renderer:first-of-type:after {
		position: absolute;
		top: -7px;
		content: '';
		height: 2px;
		left: 0;
		right: 0;
		background-color: rgba(200, 200, 200, .4);
		pointer-events: none;
	}

	paper-listbox ytmusic-menu-service-item-renderer:last-of-type {
		margin-bottom: 13px;
		position: relative;
	}

	paper-listbox ytmusic-menu-service-item-renderer:last-of-type:after {
		position: absolute;
		bottom: -7px;
		content: '';
		height: 2px;
		left: 0;
		right: 0;
		background-color: rgba(200, 200, 200, .4);
		pointer-events: none;
	}

	.yt-simple-endpoint.ytmusic-menu-navigation-item-renderer:hover,
	ytmusic-toggle-menu-service-item-renderer:hover,
	ytmusic-menu-service-item-renderer:hover {
		background-color: rgba(255, 255, 255, 0.1)!important;
	}

	.yt-simple-endpoint.ytmusic-menu-navigation-item-renderer,
	ytmusic-toggle-menu-service-item-renderer,
	ytmusic-menu-service-item-renderer {
		transition: background-color 200ms;
	}

	.yt-simple-endpoint.ytmusic-menu-navigation-item-renderer:hover .icon,
	.yt-simple-endpoint.ytmusic-menu-navigation-item-renderer:hover .text,
	ytmusic-toggle-menu-service-item-renderer:hover .icon,
	ytmusic-toggle-menu-service-item-renderer:hover .text,
	ytmusic-menu-service-item-renderer:hover .icon,
	ytmusic-menu-service-item-renderer:hover .text {
		fill: #fff;
		color: #fff !important;
		transition: 200ms;
	}

	.icon.ytmusic-menu-service-item-renderer,
	.icon.ytmusic-toggle-menu-service-item-renderer,
	.icon.ytmusic-menu-navigation-item-renderer {
		height: 18px;
		width: 18px;
		margin: 0 10px 0 0;
	}

	paper-listbox.ytmusic-menu-popup-renderer {
		padding: 6px 0;
	}

	iron-dropdown {
		box-shadow: 0 4px 16px 2px rgba(0, 0, 0, .3);
		border-radius: 4px;
	}

	ytmusic-player-bar:not([repeat-mode_="NONE"]) .repeat.ytmusic-player-bar {
		color: #ff0000;
	}

	.subtitle.ytmusic-detail-header-renderer,
	.second-subtitle.ytmusic-detail-header-renderer,
	.byline.ytmusic-data-bound-header-renderer yt-formatted-string.ytmusic-data-bound-header-renderer,
	.stats.ytmusic-data-bound-header-renderer {
		font-size: 14px;
		line-height: 16pt
	}

	ytmusic-tabs.iron-selected .tab.ytmusic-tabs,
	.tab.selected.ytmusic-tabs {
		color: #ff0000;
		border-bottom: 2px solid #ff0000;
	}

	ytmusic-chip-cloud-chip-renderer:not([chip-style="STYLE_PRIMARY"]):not([chip-style="STYLE_SECONDARY"]) a.ytmusic-chip-cloud-chip-renderer {
		margin-top: 12px;
		margin-bottom: 12px;
	}


	ytmusic-tabs,
	ytmusic-search-page.stuck ytmusic-header-renderer.ytmusic-search-page {
		background-color: #202020 !important;
		box-shadow: none !important;
	}

	ytmusic-tabbed-search-results-renderer #contents.ytmusic-section-list-renderer > *.ytmusic-section-list-renderer:not(.fullbleed) {
		width: unset;
		margin: 0;
	}

	ytmusic-search-page .tab-container.ytmusic-tabs {
		border-bottom: 0;
		margin-left: 64px;
		margin-top: 24px;
	}

	.content.ytmusic-tabbed-search-results-renderer {
		padding-top: 64px;
	}

	ytmusic-dialog #content {
		padding-top: 0;
	}

	ytmusic-app-layout {
		background: #202020;
		position: absolute;
		object-fit: contain !important;
		top: 0;
		overflow: auto;
		width: calc(100vw - 98px);
		margin-left: 78px;
		height: calc(100vh - 12px);
		border-radius: 8px;
	}

	ytmusic-app-layout::-webkit-scrollbar {
		display: none;
	}

	.details.ytmusic-carousel-shelf-basic-header-renderer .title > a.yt-simple-endpoint.yt-formatted-string,
	ytmusic-shelf-renderer .title > yt-formatted-string > a.yt-simple-endpoint.yt-formatted-string {
		font-size: var(--ytmusic-display-2_-_font-size) !important;
	}
	ytmusic-pivot-bar-item-renderer.ytmusic-pivot-bar-renderer:hover {
		background-color: #fafafa;
	}

	.details.ytmusic-two-row-item-renderer {
		margin-top: 4px;
	}
	ytmusic-app-layout > #content {
		margin-bottom: unset !important;
		padding-bottom: unset !important;
	}
	ytmusic-detail-header-renderer,
	ytmusic-data-bound-header-renderer {
		margin-bottom: 0;
	}
	ytmusic-responsive-list-item-renderer,
	ytmusic-list-item-renderer {
		padding: 0.5vh 2vw;
		border-radius: 4px;
	}
	ytmusic-section-list-renderer[page-type="MUSIC_PAGE_TYPE_ALBUM"] paper-icon-button {
		height: 36px;
		width: 36px;
	}

	#contents.ytmusic-playlist-shelf-renderer > *.ytmusic-playlist-shelf-renderer:not(:last-child) {
		border-bottom: 0px solid rgba(255, 255, 255, 0.1);
	}

	#contents.ytmusic-playlist-shelf-renderer > *.ytmusic-playlist-shelf-renderer:not(:last-child)::after {
		border-bottom: 1px solid rgba(255, 255, 255, 0.075);
		content: '';
		height: 1px;
		width: 100%;
		left: 0;
		position: absolute;
		bottom: 0;
	}

	.title.ytmusic-immersive-header-renderer {
		font-weight: 500;
		font-size: 32pt;
		line-height: 60pt;
	}

	.image.ytmusic-immersive-header-renderer ~ .content-container-wrapper.ytmusic-immersive-header-renderer {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.image.ytmusic-immersive-header-renderer ~ .content-container-wrapper.ytmusic-immersive-header-renderer .gradient-container.ytmusic-immersive-header-renderer {
		background: linear-gradient(360deg, #202020 20%, rgba(0, 0, 0, 0) 100%);
		padding-top: 72px;
	}

	ytmusic-immersive-header-renderer {
		margin-bottom: 0;
	}

	#contents.ytmusic-section-list-renderer > *.ytmusic-section-list-renderer:not(.fullbleed) .title.ytmusic-shelf-renderer {
		margin: 4vh 0;
	}

	ytmusic-section-list-renderer[main-page-type="MUSIC_PAGE_TYPE_ARTIST"] > #contents.ytmusic-section-list-renderer > *.ytmusic-section-list-renderer:not(.fullbleed) .title.ytmusic-shelf-renderer {
		margin: 2vh 0;
	}

	#contents.ytmusic-section-list-renderer > *.ytmusic-section-list-renderer:not(.fullbleed) .title.ytmusic-shelf-renderer yt-formatted-string {
		color: #f0f0f0 !important;
	}

	#contents.ytmusic-section-list-renderer > *.ytmusic-section-list-renderer:not(.fullbleed) .title.ytmusic-shelf-renderer yt-formatted-string a {
		line-height: 30pt;
		color: #f0f0f0 !important;
	}

	ytmusic-grid-renderer[grid-type="artist_secondary_page"] {
		padding: 0 5.5vw;
		max-width: calc(100vw - 220px) !important;
	}

	#contents.ytmusic-section-list-renderer > *.ytmusic-section-list-renderer:not(.fullbleed) .more-button {
		margin: 0 4vw;
		margin-top: 8px;
	}

	ytmusic-section-list-renderer[main-page-type="MUSIC_PAGE_TYPE_ARTIST"] > #contents.ytmusic-section-list-renderer > *.ytmusic-section-list-renderer:not(.fullbleed) .more-button {
		margin: unset !important;
	}

	#more-content-button.ytmusic-carousel-shelf-basic-header-renderer yt-button-renderer.ytmusic-carousel-shelf-basic-header-renderer {
		position: absolute;
		right: 5.5vw;
		top: 6vh;
		--yt-paper-button-height: 20px;
		--yt-paper-button-min-width: 0;
	}

	.secondary-flex-columns.ytmusic-responsive-list-item-renderer .flex-column.ytmusic-responsive-list-item-renderer * {
		font-size: 13px;
	}

	tp-yt-paper-listbox.ytmusic-menu-popup-renderer {
		padding: 0;
		padding: 8px 8px;
		margin-top: 0 !important;
		width: auto;
		height: auto;
		border-radius: 4px;
		min-width: 20vw;
		border: 1px solid rgba(255, 255, 255, 0.2);
	}

	ytmusic-menu-popup-renderer::-webkit-scrollbar {
		display: none;
	}

	ytmusic-menu-popup-renderer {
		border-radius: 4px;
	}


	ytmusic-section-list-renderer[page-type="MUSIC_PAGE_TYPE_TRACK_RELATED"].ytmusic-tab-renderer {
		max-width: calc(100vw - 200px) !important;
		padding-bottom: 8vh;
	}

	ytmusic-section-list-renderer[page-type="MUSIC_PAGE_TYPE_TRACK_RELATED"] ytmusic-carousel-shelf-renderer {
		max-width: calc(100vw - 200px) !important;
		margin: 0 3.25vw;
	}

	ytmusic-section-list-renderer[page-type="MUSIC_PAGE_TYPE_TRACK_RELATED"] ytmusic-carousel-shelf-renderer > ytmusic-carousel-shelf-basic-header-renderer {
		margin: 6vh 3.25vw 3vh 1.75vw !important
	}

	ytmusic-section-list-renderer[page-type="MUSIC_PAGE_TYPE_TRACK_RELATED"] ytmusic-carousel.carousel.ytmusic-carousel-shelf-renderer {
		margin: 0 2vw !important;
	}

	ytmusic-section-list-renderer[page-type="MUSIC_PAGE_TYPE_TRACK_RELATED"] ytmusic-carousel:not([num-items-per-column="1"]) #items.ytmusic-carousel > *.ytmusic-carousel {
		padding-left: 0 !important;
		width: 330px;
	}

	ytmusic-section-list-renderer[page-type="MUSIC_PAGE_TYPE_TRACK_RELATED"] #items.ytmusic-carousel > .ytmusic-carousel[aspect-ratio="MUSIC_TWO_ROW_ITEM_THUMBNAIL_ASPECT_RATIO_SQUARE"] {
		width: 130.25px
	}

	ytmusic-section-list-renderer[page-type="MUSIC_PAGE_TYPE_TRACK_RELATED"] .next-items-button.ytmusic-carousel {
		right: 3.5vw;
		top: calc(100% - 62.5%);
		z-index: 2 !important;
	}

	ytmusic-section-list-renderer[page-type="MUSIC_PAGE_TYPE_TRACK_RELATED"] .previous-items-button.ytmusic-carousel {
		left: 3vw;
		top: calc(100% - 62.5%);
	}

	.autoplay {
		padding: 2vh 3.25vw !important;
	}

	ytmusic-player-page {
		z-index: 3 !important;
	}

	ytmusic-player-page ytmusic-description-shelf-renderer {
		padding: 5vh 5.5vw 1vh 5.5vw;
	}

	.ytmusic-player-page > #tabsContainer.tp-yt-paper-tabs {
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
	}

	tp-yt-paper-tab:not(.iron-selected) > .tab-content.tp-yt-paper-tab {
		opacity: 0.6;
	}

	ytmusic-section-list-renderer[main-page-type="MUSIC_PAGE_TYPE_ARTIST"] {
		padding-bottom: 6vh;
	}

	ytmusic-section-list-renderer.ytmusic-tabbed-search-results-renderer ytmusic-chip-cloud-renderer {
		padding: 1vh 4.5vw 0 4.5vw;
	}

	ytmusic-item-thumbnail-overlay-renderer[content-position=MUSIC_ITEM_THUMBNAIL_OVERLAY_CONTENT_POSITION_BOTTOM_RIGHT] #content.ytmusic-item-thumbnail-overlay-renderer > *.ytmusic-item-thumbnail-overlay-renderer {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	tp-yt-paper-toast {
		left: 78px !important;
		margin-bottom: 24px !important;
	}

	.right-controls.ytmusic-player-bar {
		position: absolute;
		top: 16px;
		width: 35vw !important;
		right: -5vw;
	}

	ytmusic-navigation-button-renderer[button-style=STYLE_ICON] button.ytmusic-navigation-button-renderer {
		font-size: 13pt;
	}

	@media (max-width: 1149px) {
		ytmusic-detail-header-renderer {
			--ytmusic-detail-header-renderer-description-width: 490px !important;
		}

		.right-controls.ytmusic-player-bar {
			width: 20vw !important;
		}
	}
}
